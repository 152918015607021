



















































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { mask } from 'vue-the-mask'
import i18n from '@/i18n'

@Component({
  layout: 'unAuthenticated',
  directives: {
    mask,
  },
})
export default class UnSubscribe extends Vue {
  public $refs: Vue['$refs'] & {
    form: {
      validate: any // eslint-disable-line
    }
  }

  private language = 'sv'

  private saving = false
  private loadingData = true
  private loadingError = false
  private loadedData = false

  private notificationTypes = []
  private selectedNotificationTypes = []

  private token = null

  private created() {
    const q = this.$route.query

    if (q.language) {
      this.language = q.language.toString()
    }

    i18n.setLanguage(this.language)

    this.token = q.token

    this.$axios
      .get('/v3/email/unsubscribe/get_initial_data')
      .then((response) => {
        this.notificationTypes = response.data.data.notificationTypes
        this.loadedData = true
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, _i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('Error') as string,
          })
        })
        this.loadingError = true
      })
      .finally(() => {
        this.loadingData = false
      })
  }

  private save() {
    if (this.$refs.form.validate()) {
      this.saving = true
      const data = {}

      this.$axios
        .post('/v3/supplier/order?token=' + this.token, data)
        .then((_response) => {
          vxm.alert.info({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Saved') as string,
          })
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, _i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
